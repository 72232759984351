(function (angular, _) {
    const  MyHippoFormlyDirectives = angular.module('MyHippoFormly.Directives');
    MyHippoFormlyDirectives.directive('mhPolicyDetailUpdateSecondaryPhone', mhProducerDetailSubOrg);
    mhProducerDetailSubOrg.$inject = ['toaster', 'APIService'];
    function mhProducerDetailSubOrg (toaster, APIService) {
        return {
            restrict: 'E',
            templateUrl: 'common/formly-directives/policy-update-secondary-phone-number/template.html',
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope) {
                $scope.errorMessage = '';
                $scope.isEditMode = false;
                $scope.isUpdating = false;

                $scope.$watch('[isEditMode, isUpdating]', () => {
                    $scope.isDisabled = !$scope.isEditMode || $scope.isUpdating;
                });

                $scope.phoneNumber = _.get($scope.model, 'personal_information.phone_number_2');
                let lastSavedNumber = $scope.phoneNumber;

                $scope.cancel = () => {
                    $scope.phoneNumber = lastSavedNumber;
                    $scope.errorMessage = '';
                    $scope.toggleEditMode();
                };

                $scope.save = () => {
                    const phoneRegex = /^[0-9]{10}$/;
                    if ($scope.phoneNumber && !$scope.phoneNumber.match(phoneRegex)) {
                        $scope.errorMessage = 'Invalid Phone Number';
                    } else {
                        const policyId = $scope.model.id;
                        console.log($scope.phoneNumber);
                        APIService.updatePersonalInformation(policyId, { phone_number_2: $scope.phoneNumber }).then(() => {
                            toaster.pop('success', 'Successfully saved phone number');
                            lastSavedNumber = $scope.phoneNumber;
                            $scope.errorMessage = '';
                            $scope.toggleEditMode();
                        }).catch(err => {
                            toaster.pop('error', 'Unable to save phone number');
                        });
                    }
                };

                $scope.toggleEditMode = () => {
                    $scope.isEditMode = !$scope.isEditMode;
                };
            }
        };
    }
})(window.angular, window._);
